import React, {
  useEffect, useRef, useState, useCallback, useMemo,
} from 'react';
import {
  Table, Button, message, Popconfirm, Divider, Typography, Spin,
} from 'antd';
import dayjs  from 'dayjs';
import {
  AppleFilled, CheckCircleFilled, DeleteOutlined, GoogleOutlined, UserOutlined,
} from '@ant-design/icons';
import { Line } from '@ant-design/charts';

import type { UserObj } from '../../types';
import useSearchFilter from '../../hooks/useSearchFilter';
import { getFunctions, httpsCallable } from 'firebase/functions';

const { Column } = Table;

type UserObjCached = UserObj & { dateKey: string };

const AppUsers = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<Array<UserObjCached>>([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState<string | null>(null);
  const [deleting, setDeleting] = useState<boolean | string>(false);
  const nameFilterRef = useRef(null);
  const emailFilterRef = useRef(null);

  const loadUsers = useCallback(async (refresh = false) => {
    setLoading(true);
    try {
      const getUsers = httpsCallable<{ refresh: boolean }, string>(getFunctions(), 'getUsers', {
        timeout: 180000,
      });
      const response = await getUsers({ refresh });

      const dataFileURL = response.data;
      const data = await fetch(dataFileURL).then((res) => res.json());
      setUsers(data.users.map((user: UserObj) => {
        const created = dayjs.unix(user.metadata.creationTime);
        return {
          ...user,
          dateKey: created.format('YYYY-MM-DD'),
        };
      }));
      setLastUpdatedAt(
        new Date(data.updatedAt)
          .toLocaleString('en-US', { dateStyle: 'long', timeStyle: 'short' }),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! something went wrong while fetching users.');
    }
    setLoading(false);
  }, []);

  useEffect(() => { loadUsers(); }, [loadUsers]);

  const deleteUser = useCallback(async (user: UserObj) => {
    setDeleting(user.uid);

    try {
      const deleteUserFn = httpsCallable<{ uid: string }>(getFunctions(), 'deleteUser');
      await deleteUserFn({
        uid: user.uid,
      });
      message.success('Successfully deleted user.');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! something went wrong while deleting user.');
    }

    await loadUsers(true);
    setDeleting(false);
  }, [loadUsers]);

  const { filterDropdown, searchFilterIcon, filterDropdownProps } = useSearchFilter();

  const lineChartData = useMemo(() => {
    const newUsersMap = users
      .reduce((obj: { [provider: string]: { [date: string]: number } }, user: UserObjCached) => {
        const date = user.dateKey;
        const providerId = user.providers.length > 0 ? user.providers[0] : 'anonymous';
        let provider = '';
        switch (providerId) {
          case 'google.com':
            provider = 'Google';
            break;

          case 'apple.com':
            provider = 'Apple';
            break;

          case 'password':
            provider = 'Email';
            break;

          case 'anonymous':
            provider = 'Anonymous';
            break;

          default:
            provider = 'Unknown';
            break;
        }

        if (!obj[provider]) {
          // eslint-disable-next-line no-param-reassign
          obj[provider] = {};
        }

        if (obj[provider][date]) {
          // eslint-disable-next-line no-param-reassign
          obj[provider][date] += 1;
        } else {
          // eslint-disable-next-line no-param-reassign
          obj[provider][date] = 1;
        }
        return obj;
      }, {});

    return Object
      .keys(newUsersMap)
      .reduce((arr, provider) => [
        ...arr,
        ...Object.keys(newUsersMap[provider])
          .map((date) => ({ date, provider, count: newUsersMap[provider][date] })),
      ], [] as Array<{ date: string, provider: string, count: number }>)
      .sort((a, b) => a.date.localeCompare(b.date));
  }, [users]);

  return (
    <>
      {lastUpdatedAt && (
        <div style={{ textAlign: 'revert' }}>
          <p>
            {'Showing cached results. Last updated on '}
            <strong>{lastUpdatedAt}</strong>
            <Button type="link" onClick={() => loadUsers(true)}>
              Refresh?
            </Button>
          </p>
        </div>
      )}
      <Table
        size="small"
        loading={loading}
        dataSource={users}
        rowKey={(user: UserObj) => user.uid}
        locale={{ emptyText: loading ? 'Loading...' : 'Sorry, you do not have any users.' }}
      >
        <Column
          title="Photo"
          dataIndex="photoURL"
          render={(photoURL) => (<img src={photoURL} alt="" height="80" width="80" />)}
        />
        <Column
          title="Name"
          dataIndex="displayName"
          render={(name: string, user: UserObj) => {
            if (user.providers.length === 0) {
              return '(anonymous)';
            }
            return name;
          }}
          sorter={(
            a: UserObj, b: UserObj,
          ) => (a.displayName ? a.displayName.localeCompare(b.displayName || '') : 0)}
          filterDropdown={filterDropdown('name', nameFilterRef)}
          filterIcon={searchFilterIcon}
          onFilter={(value, user: UserObj) => Boolean(user
            .displayName?.toLowerCase().includes((value as string).toLowerCase()))}
          filterDropdownProps={filterDropdownProps(nameFilterRef)}
        />
        <Column
          title="Email"
          dataIndex="email"
          sorter={(a: UserObj, b: UserObj) => a.email.localeCompare(b.email)}
          render={(email) => email}
          filterDropdown={filterDropdown('email', emailFilterRef)}
          filterIcon={searchFilterIcon}
          onFilter={(value, user: UserObj) => Boolean(
            user?.email?.toLowerCase().includes((value as string).toLowerCase()),
          )}
          filterDropdownProps={filterDropdownProps(emailFilterRef)}
        />
        <Column
          title="Providers"
          dataIndex="providers"
          render={(providers: UserObj['providers']) => {
            if (providers.length === 0) {
              return <UserOutlined key="anonymous" />;
            }
            return providers.map((providerId) => {
              if (providerId === 'apple.com') {
                return <AppleFilled key="apple.com" />;
              }
              if (providerId === 'google.com') {
                return <GoogleOutlined key="google.com" />;
              }
              return <UserOutlined key="password" />;
            });
          }}
          filters={[{
            text: 'Apple',
            value: 'apple.com',
          }, {
            text: 'Google',
            value: 'google.com',
          }, {
            text: 'Email',
            value: 'password',
          }, {
            text: 'Anonymous',
            value: 'anonymous',
          }]}
          defaultFilteredValue={['apple.com', 'google.com', 'password']}
          onFilter={(value, user: UserObj) => {
            if (value === 'anonymous' && user.providers.length === 0) {
              return true;
            }
            return user.providers.includes(value as string);
          }}
        />
        <Column
          title="Donor?"
          dataIndex="customClaims"
          filters={[{
            text: 'Show only donors',
            value: true,
          }]}
          onFilter={(value, user: UserObj) => user.customClaims?.isDonor === value}
          render={(customClaims: UserObj['customClaims']) => {
            if (customClaims?.isDonor) {
              return <CheckCircleFilled style={{ color: '#0b8235' }} />;
            }
            return null;
          }}
        />
        <Column
          title="Created"
          dataIndex="metadata"
          sorter={(
            a: UserObjCached, b: UserObjCached,
          ) => a.metadata.creationTime - b.metadata.creationTime}
          defaultSortOrder="descend"
          render={(metadata) => dayjs.unix(metadata.creationTime).format('YYYY-MM-DD HH:mm')}
        />
        <Column
          title="Last Sign In"
          dataIndex="metadata"
          sorter={(
            a: UserObjCached, b: UserObjCached,
          ) => a.metadata.lastSignInTime - b.metadata.lastSignInTime}
          render={(metadata) => dayjs.unix(metadata.lastSignInTime).format('YYYY-MM-DD HH:mm')}
        />
        <Column
          title="Actions"
          render={(_, user: UserObj) => (
            <Popconfirm
              title="Are you sure you want to delete this user?"
              onConfirm={() => deleteUser(user)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                danger
                ghost
                size="small"
                icon={<DeleteOutlined />}
                title="Delete user"
                disabled={Boolean(deleting)}
                loading={deleting === user.uid}
              />
            </Popconfirm>
          )}
        />
      </Table>
      <Divider />
      <Typography.Title level={5}>New users</Typography.Title>
      <Spin spinning={loading}>
        <Line
          data={lineChartData}
          xField="date"
          yField="count"
          seriesField="provider"
          padding="auto"
          colorField="provider"
        />
      </Spin>
    </>
  );
};

export default AppUsers;
