import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { DiffOutlined } from '@ant-design/icons';
import {
  List, Typography, Tag, Select, Divider, Modal, Button,
} from 'antd';
import dayjs from 'dayjs';
import ReactDiffViewer from 'react-diff-viewer';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { collection, getFirestore, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { DateTime } from 'luxon';

import type { ActivityObj } from '../types';
import AuthedPage from './AuthedPage';
import { capitalize, getChangedKeys } from '../utils';
import useAdminUsers from '../hooks/useAdminUsers';

const ReactDiffWrapper = styled.div`
  overflow-x: auto;
`;

const actionNameMap = {
  create: 'created',
  update: 'updated',
  delete: 'deleted',
};

const collectionSingularNameMap = {
  docs: 'doc',
  'docs.metadata': 'metadata for doc',
  'docs.subtitles': 'subtitle for doc',
  folders: 'folder',
  'folders.folderConditions': 'condition for folder',
  files: 'file',
  extras: '',
  settings: 'settings',
};

function Activity() {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [activityLogs, setActivityLogs] = useState<Array<ActivityObj>>([]);
  const [diffViewLog, setDiffViewLog] = useState<ActivityObj | null>(null);
  const { users, loading: loadingUsers } = useAdminUsers();

  const startDateOptions = useMemo(() => [
    {
      label: 'Today',
      value: dayjs().startOf('day').toISOString(),
    }, {
      label: 'This week',
      value: dayjs().startOf('week').toISOString(),
    }, {
      label: 'Last 7 days',
      value: dayjs().subtract(7, 'days').toISOString(),
    }, {
      label: 'This month',
      value: dayjs().startOf('month').toISOString(),
    }, {
      label: 'Last 30 days',
      value: dayjs().subtract(30, 'days').toISOString(),
    },
  ], []);

  useEffect(() => {
    if (!startDate) {
      setStartDate(startDateOptions[2].value);
      return undefined;
    }

    const unsubscribe = onSnapshot(
      query(collection(getFirestore(), 'activity-logs'), where('createdAt', '>=', new Date(startDate)), orderBy('createdAt', 'desc')),
      (snapshot) => {
        if (snapshot.empty) {
          setLoading(false);
          return;
        }

        setActivityLogs(
          snapshot.docs
            .map((doc) => doc.data() as ActivityObj)
            .filter((activity) => {
              const dataBefore = activity.dataBefore || {};
              const dataAfter = activity.dataAfter || {};

              // Filter out changes that only update the amaraSyncedAt field as they are too numerous
              if (activity.collection === 'docs.metadata') {
                const changedKeys = getChangedKeys(dataBefore, dataAfter);
                if (changedKeys.length === 1 && changedKeys.includes('amaraSyncedAt')) {
                  return false;
                }
              }

              return true;
            }),
        );
        setLoading(false);
      });

    return unsubscribe;
  }, [startDate, startDateOptions]);

  const renderItem = useCallback((activityLog: ActivityObj) => {
    const {
      collection: collectionName, changeType, dataBefore, dataAfter, createdAt, parentId, docId, authId,
    } = activityLog;
    let action = actionNameMap[changeType];
    const title = (dataAfter || dataBefore)?.title || null;

    if (dataBefore && dataAfter) {
      const changedKeys = getChangedKeys(dataBefore, dataAfter);

      if (changedKeys.length >= 1 && changedKeys.includes('order') && changedKeys.includes('updatedAt') ) {
        action = 'moved';
      }
    }

    if (collectionName === 'files' && changeType === 'create') {
      action = 'uploaded';
    }

    let link = null;
    if (collectionName === 'docs' && (dataAfter || dataBefore)?.tab) {
      link = `/${(dataAfter || dataBefore)?.tab}/docs/${docId}`;
    }

    const [parentCollection, subCollection] = collectionName.split('.');

    return (
      <List.Item
        extra={changeType === 'update' && action !== 'moved' && (
          <Button
            type="primary"
            icon={<DiffOutlined />}
            title="View changes"
            onClick={() => setDiffViewLog(activityLog)}
          />
        )}
      >
        <div>
          {capitalize(action)}
          {collectionSingularNameMap[collectionName] && ` ${collectionSingularNameMap[collectionName]} `}
          {title && (
            <>
              {link ? (<Link to={link}>{title}</Link>) : (<strong>{title}</strong>)}
            </>
          )}
          {collectionName === 'docs' && ` ${changeType === 'delete' ? 'from' : 'in'} ${(dataAfter || dataBefore)?.tab || 'unknown'}`}
          {collectionName === 'extras' && ' in extras'}
          {action === 'moved' && ` from position ${(dataBefore?.order || 0) + 1} to position ${(dataAfter?.order || 0) + 1}`}
        </div>
        {parentId && (
          <div>
            <small>
              <strong>
                {parentCollection}
              </strong>
              {' '}
              ID:
              {' '}
              <code>
                {parentId}
              </code>
              ,
              {' '}
              <strong>
                {subCollection}
              </strong>
              {' '}
              ID:
              {' '}
              <code>
                {docId}
              </code>
            </small>
          </div>
        )}
        <div>
          {
            changeType === 'update'
              && action !== 'moved'
              && dataBefore
              && dataAfter
              && getChangedKeys(dataBefore, dataAfter).map((key) => <Tag key={key}>{key}</Tag>)
          }
        </div>
        <Typography.Text type="secondary" title={dayjs(createdAt.toDate()).toISOString()}>
          {DateTime.fromJSDate(createdAt.toDate()).toRelative()}
          {authId && users.find((user) => user.uid === authId) && (
            <>
              {' by '}
              {users.find((user) => user.uid === authId)?.displayName || 'Unknown'}
            </>
          )}
        </Typography.Text>
      </List.Item>
    );
  }, [users]);

  return (
    <AuthedPage requiredPermission="edit_content">
      <Helmet>
        <title>Activity</title>
      </Helmet>

      <Select value={startDate || ''} onChange={setStartDate} style={{ width: 150 }}>
        {startDateOptions.map(({ label, value }) => (
          <Select.Option key={value} value={value}>{label}</Select.Option>
        ))}
      </Select>
      <Divider />
      <List
        itemLayout="vertical"
        dataSource={activityLogs}
        loading={loading || loadingUsers}
        renderItem={renderItem}
      />
      {diffViewLog && diffViewLog.dataBefore && diffViewLog.dataAfter && (
        <Modal
          open
          title={`Changes in "${diffViewLog.collection === 'settings' ? 'settings' : diffViewLog.dataAfter.title || ''}"`}
          width={1024}
          onCancel={() => setDiffViewLog(null)}
          footer={null}
        >
          <ReactDiffWrapper>
            <ReactDiffViewer
              oldValue={JSON.stringify(diffViewLog.dataBefore, null, 2)}
              newValue={JSON.stringify(diffViewLog.dataAfter, null, 2)}
              splitView
              showDiffOnly
              hideLineNumbers
            />
          </ReactDiffWrapper>
        </Modal>
      )}
    </AuthedPage>
  );
}

export default Activity;
