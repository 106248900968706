import { collection, deleteDoc, doc, getDocs, getFirestore, query } from 'firebase/firestore';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FirestoreCollection, languageNames, supportedLanguageCodes } from '../../config';
import { Button, Divider, InputRef, message, Popconfirm, Table } from 'antd';
import useSearchFilter from '../../hooks/useSearchFilter';
import { DeleteOutlined, EditOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Quote } from './QuotesWrapper';
import { Contributor } from '../../config';

const getEditUrl = (item: Quote) => {
  return `/quotes/quotes/${item.id}`;
};

const Quotes = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [quotes, setQuotes] = useState<Array<Quote>>([]);
  const [deleting, setDeleting] = useState<Array<string>>([]);
  const searchInput = useRef<InputRef>(null);
  const { filterDropdown, renderHighlight, searchText, searchFilterIcon, filterDropdownProps } = useSearchFilter();
  const navigate = useNavigate();

  const columnRenderer = useCallback((values: string[]): JSX.Element => {
    return (
      <>{
        values.map(value => 
          <div key={value} style={{ whiteSpace: 'nowrap' }}>
            {renderHighlight(value)}
          </div>,
        )}
      </>
    );
  }, [renderHighlight]);

  const reloadQuotes = useCallback(async () => {

    setLoading(true);
    const quotesSnapshot = await getDocs(query(collection(getFirestore(), FirestoreCollection.QUOTES)));
    const authorsSnapshot = await getDocs(query(collection(getFirestore(), FirestoreCollection.CONTRIBUTORS)));

    if (quotesSnapshot.metadata.fromCache || authorsSnapshot.metadata.fromCache) {
      throw new Error('No Internet!');
    }
    const loadingQuotes: Array<Quote> = [];

    quotesSnapshot.forEach((quoteItem) => {
      const quoteData = quoteItem.data() as Omit<Quote, 'id'>;
      const quoteId = quoteItem.id;
      const quote: Quote = {
        id: quoteId,
        ...quoteData,
      };

      const authorSnapshot = authorsSnapshot.docs.find(authorDoc => authorDoc.id === quoteData.authorRef.id);
      if (!authorSnapshot || !authorSnapshot.exists()) {
        throw new Error('Author reference does not point to a valid object in firestore');
      }

      const authorData = authorSnapshot.data() as Partial<Contributor>;

      if (!authorData.en) {
        throw new Error('Author reference has no author name defined in English');
      }
      quote.authorName = authorData.en;

      loadingQuotes.push(quote);
    });

    setQuotes(loadingQuotes);
    setLoading(false);
  }, []);

  useEffect(() => {
    reloadQuotes();
  }, [reloadQuotes]);

  const handleDelete = useCallback(async (item: Quote) => {
    setDeleting([...deleting, item.id]);

    try {
      await deleteDoc(doc(collection(getFirestore(), FirestoreCollection.QUOTES), item.id));
      setQuotes((prevDocs) => prevDocs.filter(({ id }) => id !== item.id));
      setDeleting((prevDeleting) => prevDeleting.filter((id) => id !== item.id));
    } catch (err) {
      console.error(err);
      message.error('Oops! something went wrong while deleting the quote.');
      setDeleting((prevDeleting) => prevDeleting.filter((id) => id !== item.id));
    }
  }, [deleting]);


  return (
    <>
      <div className="TableHeader__wrapper">
        <Button
          type="primary"
          ghost
          loading={loading}
          icon={<ReloadOutlined />}
          onClick={reloadQuotes}
        >
          Reload
        </Button>
        <Button
          type="primary"
          onClick={() => navigate('/quotes/quotes/new')}
          disabled={loading}
          icon={<PlusOutlined />}
        >
          Add new
        </Button>
      </div>
      <Table
        loading={loading}
        dataSource={quotes}
        rowKey={(item: Quote) => item.id}
        locale={{ emptyText: searchText ? 'Nothing found matching your search' : 'Sorry, you do not have any quotes (yet).' }}
        scroll={{ x: true }}
      >
        <Table.Column
          title="#"
          dataIndex=""
          width={'5.5%'}
          render={(text: string, quote: Quote) => (
            <span style={{ whiteSpace: 'nowrap' }}>
              {quotes.indexOf(quote) + 1}
            </span>
          )}
        />
        <Table.Column
          title="Actions"
          key="actions"
          width={'13.5%'}
          render={(text: string, item: Quote) => {
            const { id } = item;
            const isBeingDeleted = deleting.includes(id);
            const confirmTitle = (
              <span>
                Are you sure you want to delete this quote?
              </span>
            );

            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                <Button
                  type="primary"
                  size="small"
                  icon={<EditOutlined />}
                  onClick={() => navigate(getEditUrl(item))}
                  disabled={isBeingDeleted}
                />
                <Divider type="vertical" />
                <Popconfirm
                  title={confirmTitle}
                  onConfirm={() => handleDelete(item)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    danger
                    size="small"
                    ghost
                    icon={<DeleteOutlined />}
                    loading={isBeingDeleted}
                  />
                </Popconfirm>
              </div>
            );
          }}
        />
        <Table.Column
          title="Author"
          dataIndex=""
          width={'5.5%'}
          filterDropdown={filterDropdown('title', searchInput)}
          filterIcon={searchFilterIcon}
          onFilter={(value, item: Quote) => item.authorName.toLowerCase().includes((value as string).toLowerCase()) ?? false}
          filterDropdownProps={filterDropdownProps(searchInput)}
          render={(text: string, quote: Quote) => (
            columnRenderer([quote.authorName])
          )}
        />
        {
          supportedLanguageCodes.map(code =>
            <Table.Column
              key={code}
              title={languageNames[code]}
              dataIndex=""
              width={'13.5%'}
              filterDropdown={filterDropdown('title', searchInput)}
              filterIcon={searchFilterIcon}
              onFilter={(value, item: Quote) => item[code]?.variations.join(' ').toLowerCase().includes((value as string).toLowerCase()) ?? false}
              filterDropdownProps={filterDropdownProps(searchInput)}
              render={(quote: Quote) =>
                quote[code] ? columnRenderer(quote[code].variations ?? []) : <></>
              }
            />,
          )
        }
      </Table>
    </>
  );
};

export default Quotes;
