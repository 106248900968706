import { Helmet } from 'react-helmet-async';
import AuthedPage from '../AuthedPage';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary';
import { Tabs } from 'antd';
import Quotes from './Quotes';
import QuoteEdit from './QuoteEdit';
import { Timestamp, DocumentReference, FieldValue } from 'firebase/firestore';
import { SupportedLanguageCode } from '../../config';
import ScheduledQuotes from './ScheduledQuotes';
import ScheduledQuoteDetail from './ScheduledQuoteDetail';

export type QuoteInfo = {
  lastUsedAt?: Timestamp;
  variations: string[];
};

type QuoteByLanguage = {
  [key in SupportedLanguageCode]?: QuoteInfo;
};

export interface Quote extends QuoteByLanguage {
  id: string;
  authorRef: DocumentReference;
  authorName: string;
  source: string;
  createdAt: FieldValue;
  updatedAt: FieldValue;
}

const quotesTabkeys = {
  quotes: 'quotes',
  scheduled: 'scheduled',
};

const QuotesWrapper = () => {
  const tab   = useParams<'tab'>().tab ?? 'quotes';
  const navigate = useNavigate();

  const itemId   = useParams<'itemId'>().itemId;

  if (!tab ||
    (tab !== quotesTabkeys.quotes
      && tab !== quotesTabkeys.scheduled
    )
  ) {
    return <>Error - invalid tab</>;
  }

  if (itemId) {
    if (tab === quotesTabkeys.quotes) {
      return <QuoteEdit />;
    } else {
      return <ScheduledQuoteDetail />;
    }
  }

  const tabItems = [
    {
      label: 'Quotes',
      key: quotesTabkeys.quotes,
      children: (
        <ErrorBoundary>
          <Quotes />
        </ErrorBoundary>
      ),
    },
    {
      label: 'Scheduled Quotes',
      key: quotesTabkeys.scheduled,
      children: (
        <ErrorBoundary>
          <ScheduledQuotes />
        </ErrorBoundary>
      ),
    },
  ];

  return (
    <AuthedPage fullwidth requiredPermission="edit_content">
      <Helmet>
        <title>Quotes</title>
      </Helmet>
      <Tabs defaultActiveKey='quotes' activeKey={tab} onTabClick={(key: string) => {
        if (key !== tab) {
          navigate(`/quotes/${key}`);
        }
      }} items={tabItems} />
    </AuthedPage>
  );
};

export default QuotesWrapper;

